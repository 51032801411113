import { FormItemRule } from "naive-ui";
import { emailRegex } from "@/composables/emailRegex";
import {
  invalidEmailMessage,
  notAllowedSpacesMessage,
  requiredMessage,
} from "@/composables/validationMessages";

const emailValidationRules = {
  key: "email",
  required: true,
  validator(_rule: FormItemRule, value: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!value) {
        reject(Error(`*${requiredMessage("Email")}`));
      } else if (/\s/g.test(value)) {
        reject(Error(`*${notAllowedSpacesMessage("email")}`));
      } else if (!emailRegex.test(value)) {
        reject(Error(`*${invalidEmailMessage}`));
      } else {
        resolve();
      }
    });
  },
  trigger: ["input", "blur"],
};

export default emailValidationRules;

import { AxiosResponse } from "axios";
import ApiService from "@/services";
import { PaginationParamsInterface } from "@/types/api/PaginationParamsInterface";
import { CustomerStatusEnum } from "@/enums/customer/CustomerStatusEnum";
import { CustomerChangeParamsType } from "@/types/customer/CustomerChangeParamsType";
import { CustomerParamsInterface } from "@/types/api/CustomerParamsInterface";

export default class AdminCustomerService {
  static getCustomerList(
    params: PaginationParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/customer", { params });
  }

  static getCustomerDetails(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/customer/${id}`);
  }

  static changeCustomerStatus(
    id: string,
    status: CustomerStatusEnum | undefined,
    isDeleted = undefined
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/customer/${id}`, {
      ...(status !== undefined && {
        status,
      }),
      ...(isDeleted !== undefined && {
        isDeleted,
      }),
    });
  }

  static changeCustomer(
    id: string,
    payload: CustomerChangeParamsType
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/customer/${id}`, payload);
  }

  static downloadCustomersReport(
    params: CustomerParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v1/customer/report`, {
      params,
      responseType: "arraybuffer",
    });
  }
}

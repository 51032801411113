import {
  minMaxLengthMessage,
  maxLengthMessage,
  minLengthMessage,
  requiredMessage,
} from "@/composables/validationMessages";
import { FormItemRule } from "naive-ui";

const stringValidationRules = (
  field: string,
  minLength = 0,
  maxLength = 0,
  required = true,
  key = ""
): FormItemRule => {
  return {
    required,
    ...(key
      ? {
          key,
        }
      : undefined),
    validator(_rule: FormItemRule, value: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        if (required && !value) {
          reject(Error(requiredMessage(field)));
        } else if (
          maxLength &&
          minLength &&
          (value.length < minLength || value.length > maxLength)
        ) {
          reject(Error(minMaxLengthMessage(field, minLength, maxLength)));
        } else if (maxLength && value.length > maxLength) {
          reject(Error(maxLengthMessage(field, maxLength)));
        } else if (minLength && value.length < minLength) {
          reject(Error(minLengthMessage(field, maxLength)));
        } else {
          resolve();
        }
      });
    },
    trigger: ["input", "blur"],
  };
};

export default stringValidationRules;

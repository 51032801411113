import { AxiosResponse } from "axios";
import ApiService from "@/services/index";

export default class SalesRegionService {
  static getSalesRegions(): Promise<AxiosResponse> {
    return ApiService.get("/v1/sales-regions");
  }
  static getCountries(): Promise<AxiosResponse> {
    return ApiService.get("/v1/countries");
  }
}

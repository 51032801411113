import { AxiosResponse } from "axios";
import ApiService from "@/services";
import CompanyAccountInterface from "@/types/checkout-with-purchase-order/CompanyAccountInterface";
import { PaginationParamsInterface } from "@/types/api/PaginationParamsInterface";
import { CustomerParamsInterface } from "@/types/api/CustomerParamsInterface";
import UserInterface from "@/types/api/UserInterface";
import { InviteUserParamsType } from "@/types/api/InviteUserParamsType";

export default class UserService {
  static getCustomers(
    params: PaginationParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/customer/search", {
      params,
    });
  }

  static getUserById(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/users/${id}`);
  }

  static getUsers(params: {
    limit: number;
    skip: number;
    customerId?: string;
    internal?: boolean;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/users/search", {
      params,
    });
  }

  static createCustomerAccount(
    id: string,
    payload: CompanyAccountInterface
  ): Promise<AxiosResponse> {
    return ApiService.post(`/v1/customer/${id}/account`, payload);
  }

  static downloadUsersReport(
    params: CustomerParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v1/users/report`, {
      params,
      responseType: "arraybuffer",
    });
  }

  static getUser(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/users/${id}`);
  }

  static getUserList(params: {
    limit: number;
    skip: number;
    searchTexts?: string[];
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/users", {
      params,
    });
  }

  static createUser(payload: InviteUserParamsType): Promise<AxiosResponse> {
    return ApiService.post("/v1/users/create-by-admin", payload);
  }

  static updateUserAvatar(id: string, avatar: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/users/${id}/avatar`, { avatar });
  }

  static removeUserAvatar(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/users/${id}/avatar`);
  }

  static updateUser(
    id: string,
    payload: Partial<UserInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/users/${id}`, payload);
  }

  static deleteUser(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/users/${id}`);
  }
}

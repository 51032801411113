import { FormItemRule } from "naive-ui";
import {
  invalidPhoneMessage,
  requiredMessage,
} from "@/composables/validationMessages";

const phoneValidationRules = (
  key = "phoneNumber",
  required = true
): FormItemRule => {
  return {
    key,
    required,
    validator(_rule: FormItemRule, value: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        const val = value.replace(/\s+/g, "");
        if (!val && !required) {
          resolve();
        } else if (!val) {
          reject(Error(requiredMessage("Phone number")));
        } else if (!/^[+]\d{7,15}$/g.test(val)) {
          reject(Error(invalidPhoneMessage));
        } else {
          resolve();
        }
      });
    },
    trigger: ["input", "blur"],
  };
};

export default phoneValidationRules;

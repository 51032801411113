import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5098039e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "phone-number-input",
  ref: "phoneNumberInputRef"
}
const _hoisted_2 = {
  key: 0,
  class: "placeholder-phone-input"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
    ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_openBlock(), _createBlock(_component_vue_tel_input, {
          key: 1,
          id: "sign-up-phone-input",
          modelValue: $setup.inputValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.inputValue) = $event)),
          allCountries: $setup.allCountries,
          autoDefaultCountry: $setup.autoDefaultCountry,
          defaultCountry: $props.autofillCountry ? '' : 'false',
          class: _normalizeClass({error: $props.validationError}),
          onOnInput: $setup.onPhoneNumberInput,
          onCountryChanged: $setup.onCountryChanged,
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', $event))),
          onKeydownPassive: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('keydownEnter', $event)), ["enter"]))
        }, null, 8, ["modelValue", "allCountries", "autoDefaultCountry", "defaultCountry", "class"]))
  ], 512))
}